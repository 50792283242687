import { default as _91_46_46_46slug_93UHPkUyxDwtMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as _91IATA_93bpzs16J9RfMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue?macro=true";
import { default as _91product_slug_936cQkK8QY3YMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue?macro=true";
import { default as _91IATA_93KCx0utbSCzMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue?macro=true";
import { default as _91product_slug_93jrVQd2vYM6Meta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue?macro=true";
import { default as _91IATA_93wUxa0Uw7ssMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue?macro=true";
import { default as _91product_slug_938l8hcufsZUMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue?macro=true";
import { default as healthwxEeDO7P5oMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue?macro=true";
import { default as _91IATA_93gy0vKUz3tKMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93bAGA1FXdYcMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue?macro=true";
import { default as _91_46_46_46slug_9393e84OA4V4Meta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue?macro=true";
import { default as component_45stubspgkrsaiqTMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubspgkrsaiqT } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "airport-hotels-with-parking-IATA___en",
    path: "/airport-hotels-with-parking/:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue")
  },
  {
    name: "airport-hotels-with-parking-location-product_slug___en",
    path: "/airport-hotels-with-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-lounges-IATA___en",
    path: "/airport-lounges/:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue")
  },
  {
    name: "airport-lounges-location-product_slug___en",
    path: "/airport-lounges/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue")
  },
  {
    name: "airport-parking-IATA___en",
    path: "/airport-parking/:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue")
  },
  {
    name: "airport-parking-location-product_slug___en",
    path: "/airport-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue")
  },
  {
    name: "port-parking-IATA___en",
    path: "/port-parking/:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue")
  },
  {
    name: "port-parking-location-product_slug___en",
    path: "/port-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue")
  },
  {
    name: "subdomain-business-slug___en",
    path: "/subdomain/business/:slug(.*)*",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue")
  },
  {
    name: component_45stubspgkrsaiqTMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubspgkrsaiqT
  }
]